import { AppConfig } from 'internal/models';
import React, { useContext, useState } from 'react';

const ConfigContext = React.createContext<AppConfig | undefined>(undefined);

export const ConfigProvider: React.FC = ({children}) => {
    const [config] = useState<AppConfig>(new AppConfig((window as any).Env.ServiceUrls));

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    )
}

export const useConfig = () => {
    const ctx = useContext(ConfigContext);

    if (ctx === undefined) {
        throw new Error("useClient must be used within a ClientProvider!");
    }

    return ctx;
}