import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import {
  Button,
  Collapsible,
  ModalContainerIds,
  Patience,
  PortalConfirm,
  SelectOption,
  usePortalModal,
} from '@smartaction/visuals';
import { useBots, useClient, useSnapshot, useTenant } from 'contexts';
import { Environment, GetEnvName, ValidationResponse, DeployResponse, ResultType } from 'internal/models';
import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { Connections } from './Connections';
import { formatDateToLocal } from 'ui/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import ValidationResult from './ValidationResult';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { PublishModal } from './PublishModal';
import { toast } from 'react-toastify';

const environmentOptions: Array<SelectOption> = Object.values(Environment).map((e) => {
  return { label: GetEnvName(e), value: e.toString() };
});
export const PublishView = () => {
  const client = useClient('publisher');
  const duplicationClient = useClient('duplicator');
  const validatorClient = useClient('validator');
  const snapshot = useSnapshot();
  const tenant = useTenant();
  const bots = useBots();
  const location = useLocation();
  const navigate = useNavigate();
  const confirm = usePortalModal(ModalContainerIds.Confirm);
  const [flowIsValid, setFlowIsValid] = useState(false);
  const publish = usePortalModal(ModalContainerIds.Confirm);
  const [environment, setEnvironment] = useState<Environment>(Environment.DEV);
  const [validationResponse, setValidationResponse] = useState<ValidationResponse[]>();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [deploys, setDeploys] = useState<DeployResponse[]>([]);
  const deployStatusInterval = 5 * 1000;
  const deployStatusTimeout = 30 * 1000;

  const onValidate = async () => {
    setLoadingStatus(true);
    try {
      const { success, data } = await validatorClient.validate(
        tenant!.id.toString(),
        snapshot.snapshot.id,
        environment,
      );

      setFlowIsValid(success && data?.filter((v) => v.type === ResultType.Error).length === 0);

      setValidationResponse(data);
    } catch (_) {
      setFlowIsValid(false);
    } finally {
      setLoadingStatus(false);
    }
  };

  const onPublishClick = () => {
    publish.openModal(<PublishModal publish={onPublishConfirm} />);
  };

  const onPublishConfirm = async (comment: string) => {
    setValidationResponse(undefined);
    const publishingToast = toast.loading('Publishing Agent...');
    const deployingToast = toast.info('Deploying Agent...', { autoClose: false });

    setIsPublishing(true);

    const { success, data } = await client.publish(snapshot.snapshot.id, environment, comment);
    if (!success || (success && !data)) {
      toast.dismiss();
      toast(data || 'Unable to publish your agent at this time. Please contact the NOVA team for more information.', {
        type: 'error',
        autoClose: false,
      });
      setIsPublishing(false);
      return;
    }

    toast.update(publishingToast, { type: 'success', render: 'Publishing success', isLoading: false, autoClose: 8000 });
    toast.update(deployingToast, { isLoading: true });

    setFlowIsValid(true);

    let getDeployStatus = true;
    setTimeout(() => {
      getDeployStatus = false;
    }, deployStatusTimeout);

    const interval = setInterval(async () => {
      const { success: isSuccessRequest, data: isSuccess } = await client.getDeploymentResult(
        snapshot.snapshot.id,
        data!,
      );
      if (isSuccess) {
        toast.update(deployingToast, {
          type: 'success',
          render: 'Deploying success',
          isLoading: false,
          autoClose: 8000,
        });
        finishPublishing(interval);
        if (environment === Environment.PROD) {
          await bots.refreshBots();
          navigate(location.pathname.replace('draft/publish', 'published/publish'));
        }
      } else if (!isSuccessRequest || !getDeployStatus || typeof isSuccess === 'boolean') {
        toast.dismiss();
        toast('Unable to deploy your agent at this time. Please contact the NOVA team for more information.', {
          type: 'error',
          autoClose: false,
        });
        finishPublishing(interval);
      }
    }, deployStatusInterval);
  };

  const finishPublishing = async (interval: NodeJS.Timeout) => {
    setIsPublishing(false);
    clearInterval(interval);
    getDeploys();
  };

  const onDuplicate = async () => {
    confirm.openModal(
      <PortalConfirm
        header="Are you sure?"
        content={`Everything in this snapshot will be duplicated and set as the Draft snapshot for this agent, and that if a draft already exists, it will be deleted.`}
        confirmButton={{
          label: 'Confirm',
          type: VisualCategory.Primary,
          clicked: async () => {
            await onDuplicateConfirm();
          },
        }}
        cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
      />,
    );
  };

  const onDuplicateConfirm = async () => {
    setLoadingStatus(true);
    try {
      if (tenant?.id && bots.currentBot) {
        setIsPublishing(true);
        const { success } = await duplicationClient.duplicate(tenant?.id, bots.currentBot?.id, snapshot.snapshot.id);
        await bots.refreshBots();
        if (success) {
          navigate(location.pathname.replace('published', 'draft'));
        }
      }
    } catch (_) {
    } finally {
      setLoadingStatus(false);
      setIsPublishing(false);
    }
  };

  const onChangeEnv = (newValue: SingleValue<SelectOption>) => {
    setFlowIsValid(false);
    setEnvironment(newValue?.value as Environment);
  };

  const cols: ColDef[] = [
    {
      headerName: 'Published date',
      field: 'startedOn',
      width: 160,
      resizable: true,
      cellStyle: { padding: 0 },
      valueFormatter: (params) => formatDateToLocal(new Date(params.data.startedOn)),
    },
    { headerName: 'User', field: 'userName', editable: false, width: 232, resizable: true },
    {
      headerName: 'Comment',
      field: 'comment',
      width: 290,
      editable: false,
      resizable: true,
      valueFormatter: (params) => params.data.comment,
    },
  ];

  const getDeploys = () => {
    client.getLastPublished(snapshot.snapshot.id, environment).then((response) => {
      setDeploys(response.data || []);
    });
  };

  useEffect(getDeploys, [client, snapshot.snapshot.id, environment]);

  return (
    <Patience showPatience={isPublishing}>
      <div className="row g-0" style={{ minHeight: '85vh' }}>
        <div className="col-sm-6 publish-container p-3">
          <Select
            styles={{
              control: (base) => ({
                ...base,
                width: 'max-content',
              }),
            }}
            options={environmentOptions}
            defaultValue={environmentOptions[0]}
            onChange={onChangeEnv}
          />
          <div className="form-submit-btn-group">
            <Button className="form-submit-btn is--validate" action={onValidate} type={VisualCategory.Light}>
              Validate
            </Button>
            <Button
              className="form-submit-btn"
              type={VisualCategory.Success}
              action={onPublishClick}
              isDisabled={!flowIsValid}
            >
              Publish
            </Button>
            <Button className="form-submit-btn" type={VisualCategory.Primary} action={onDuplicate}>
              <Icon type={IconType.Clone} /> Copy to Draft
            </Button>
            {confirm.modal}
            {publish.modal}
          </div>
          {deploys.length > 0 && (
            <Collapsible labelContent={`Publish history`}>
              <div className="ag-theme-balham" style={{ height: 610, width: 'auto' }}>
                <AgGridReact
                  ensureDomOrder={true}
                  disableStaticMarkup={true}
                  columnDefs={cols}
                  defaultColDef={{ cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' } }}
                  rowData={deploys}
                  rowHeight={38}
                  suppressRowTransform={true}
                  stopEditingWhenCellsLoseFocus={true}
                ></AgGridReact>
              </div>
            </Collapsible>
          )}
          <ValidationResult loadingStatus={loadingStatus} data={validationResponse} />
        </div>
        <div className="col-sm-6">
          <Connections />
        </div>
      </div>
    </Patience>
  );
};
