import { arrayRemove, DefaultPublisher } from '@smartaction/common';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Field, ModalContainerIds, Patience, PortalConfirm, useId, usePortalModal } from '@smartaction/visuals';
import { CellValueChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import {
  Channel,
  ChannelType,
  ConfigType,
  DirectAssignmentPointer,
  EmptyId,
  EntryPoint,
  PointerType,
  SourceType,
} from 'internal/models';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ModuleItemRenamedEvent } from 'ui/events/ModuleItemRenamedEvent';
import { NotApplicablePointerRenderer, PointerEditor, PointerRenderer, SIPHeadersRenderer } from 'ui/controls/grid';
import { DeleteRenderer } from 'ui/controls/grid/editors/DeleteRenderer';
import ChannelTypeTableCellRenderer from 'ui/controls/table/ChannelTypeTableCellRenderer';
import { ChannelTypeTableCellEditor } from 'ui/controls/grid/editors/ChannelTypeTableCellEditor';
import { FunctionDropDown } from 'ui/components';

type EntryPointEditorProps = {
  moduleId: string;
  entryPoint: EntryPoint;
};

const colDefs = (deleteFunc: (channel: Channel) => void, editChannel: (data: Channel) => void) => [
  { headerName: 'Name', field: 'name', width: 100, editable: true },
  {
    headerName: 'Type',
    field: 'type',
    width: 220,
    cellRenderer: ChannelTypeTableCellRenderer,
    cellEditor: ChannelTypeTableCellEditor,
    cellEditorPopup: true,
    editable: true,
    autoHeight: true,
    channelTypeOptions: [
      { label: 'Web Chat', value: ChannelType.WebChat },
      { label: 'Inbound Phone', value: ChannelType.Inbound },
      { label: 'Outbound Phone', value: ChannelType.Outbound },
      { label: 'SMS', value: ChannelType.SMS },
    ],
    sourceTypeOptions: [
      { label: '--Select--', value: '' }, // Empty value needed
      { label: 'ANI', value: SourceType.ANI },
      { label: 'DNIS', value: SourceType.DNIS },
    ],
  },
  {
    headerName: 'Source',
    field: 'source',
    // Used (params: any) to mitigate issues from strongly typed params.
    cellRendererSelector: (params: any) => {
      if (!params.data) {
        return undefined;
      }
      const channel = params.data as Channel;
      if (channel.type.type === ChannelType.WebChat) {
        return {
          component: NotApplicablePointerRenderer,
        };
      }

      return {
        component: PointerRenderer,
      };
    },
    // Used (params: any) to mitigate issues from strongly typed params.
    cellEditorSelector: (params: any) => {
      if (!params.data) {
        return { component: React.Fragment };
      }
      const channel = params.data as Channel;
      if (channel.type.type === ChannelType.WebChat) {
        return { component: React.Fragment }; // WebChat Source should not be editable.
      }
      if (channel.type.type === ChannelType.SMS) {
        return {
          component: PointerEditor,
          params: {
            colDef: {
              ...params.colDef,
              allowedTypes: [PointerType.Config],
            },
            value: {
              ...params.value,
              specificConfigType: ConfigType.Twilio,
            },
          },
        };
      }
      return {
        component: PointerEditor,
        params: {
          colDef: {
            ...params.colDef,
            allowedTypes: [PointerType.Config, PointerType.DirectAssignment, PointerType.DirectAssignmentList],
          },
        },
      };
    },
    cellEditorPopup: true,
    width: 230,
    editable: true,
    allowedTypes: [PointerType.Config, PointerType.DirectAssignment, PointerType.DirectAssignmentList],
    wrapText: true,
    autoHeight: true,
  },
  {
    headerName: 'Actions',
    cellRenderer: (params: any) => {
      const type = params?.data.type?.type as ChannelType;
      const editButton = type === ChannelType.Inbound || type === ChannelType.Outbound
        ? (
          <Button type={VisualCategory.Primary} className="btn-sm me-3" action={() => editChannel(params.data)}>
            <Icon type={IconType.Edit} />
          </Button>
        ) : <React.Fragment/>;
      return (
        <div style={{ display: 'flex' }}>
          {editButton}
          <DeleteRenderer {...params} />
        </div>
      );
    },
    cellRendererParams: { deleteFunc: deleteFunc, canEdit: true },
    width: 100,
    resizable: true,
  },
];

export const EntryPointEditor: React.FC<EntryPointEditorProps> = ({ moduleId, entryPoint }) => {
  const [name, setName] = useState(entryPoint.name);
  const [description, setDescription] = useState(entryPoint.description);
  const nameId = useId('entryPointName');
  const descriptionId = useId('entryPointDescription');
  const { updateFlow } = useFlow();
  const { isReadOnlyBot } = useBots();
  const client = useClient('flow');
  const snapshotData = useSnapshot();
  const [isSaving, setIsSaving] = useState(false);
  const confirm = usePortalModal(ModalContainerIds.Modal);

  useEffect(() => {
    setName(entryPoint.name);
    setDescription(entryPoint.description);
  }, [entryPoint]);

  const saveMetadata = () => {
    if (name) {
      setIsSaving(true);
      client.modules.entryPoints
        .updateMetadataAsync(snapshotData.snapshot!.id, moduleId, entryPoint.id, name, description)
        .then(() => {
          if (name !== entryPoint.name) {
            DefaultPublisher.publish(new ModuleItemRenamedEvent(entryPoint.id, name));
          }
          updateFlow(() => {
            entryPoint.name = name;
            entryPoint.description = description;
          });
          setIsSaving(false);
        });
    } else {
      toast.warn('Name must be set - changes to name and description are not saved.');
    }
  };

  const createChannel = async () => {
    setIsSaving(true);
    const channel = new Channel(
      '',
      'New channel',
      ChannelType.WebChat,
      new DirectAssignmentPointer('https://www.smartaction.com'),
      SourceType.NotApplicable,
      [],
    );
    const result = await client.modules.entryPoints.createChannel(
      snapshotData.snapshot.id,
      moduleId,
      entryPoint.id,
      channel.name,
      channel.type.type,
      channel.source,
      channel.type.sourceType,
    );
    if (result.success) {
      channel.id = result.data!;
      updateFlow(() => {
        entryPoint.channels.push(channel);
      });
    }
    setIsSaving(false);
  };

  const save = async (event: CellValueChangedEvent) => {
    const channel = event.data as Channel;
    switch (event.colDef.field) {
      case 'name':
        if (!event.newValue) {
          toast('Name must be set! Changes are not saved.', { type: 'error' });
          return;
        }
        await client.modules.entryPoints.updateChannelName(
          snapshotData.snapshot.id,
          moduleId,
          entryPoint.id,
          channel.id,
          event.newValue,
        );
        channel.name = event.newValue;
        break;
      case 'type':
        await client.modules.entryPoints.updateChannelType(
          snapshotData.snapshot.id,
          moduleId,
          entryPoint.id,
          channel.id,
          event.newValue,
        );
        channel.type = event.newValue;
        break;
      case 'source':
        await client.modules.entryPoints.updateChannelSource(
          snapshotData.snapshot.id,
          moduleId,
          entryPoint.id,
          channel.id,
          event.newValue,
        );
        channel.source = event.newValue;
        break;
    }
    updateFlow(() => {});
  };

  const deleteChannel = async (channel: Channel) => {
    setIsSaving(true);
    const result = await client.modules.entryPoints.deleteChannel(
      snapshotData.snapshot.id,
      moduleId,
      entryPoint.id,
      channel.id,
    );
    if (result.success) {
      updateFlow(() => {
        const idx = entryPoint.channels.findIndex((c) => c.id === channel.id);
        entryPoint.channels = arrayRemove(entryPoint.channels, idx);
      });

      setIsSaving(false);
    }
  };

  const editChannel = (data: Channel) => {
    if (data.type.type === ChannelType.Inbound) {
      confirm.openModal(
        <PortalConfirm
          header="Mappings"
          content={<SIPHeadersRenderer data={data} moduleId={moduleId} entryPoint={entryPoint} />}
          confirmButton={{
            label: <React.Fragment>Save</React.Fragment>,
            type: VisualCategory.Success,
            clicked: async () => {},
          }}
          cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
        />,
      );
    } else {
      confirm.openModal(
        <PortalConfirm
          header="Functions"
          content={
            <div className={'bg-white p-2'}>
              <h6>Non-human function</h6>
              <FunctionDropDown
                disabled={isReadOnlyBot}
                functionId={data.nonHumanFunctionId || ''}
                onFunctionSelected={async (func) => {
                  if (func !== EmptyId) {
                    await client.modules.entryPoints.updateNonHumanFunctionId(
                      snapshotData.snapshot.id,
                      moduleId,
                      entryPoint.id,
                      data.id,
                      func,
                    );
                    data.nonHumanFunctionId = func;
                  }
                }}
              />
              <h6>Not Sure function</h6>
              <FunctionDropDown
                disabled={isReadOnlyBot}
                functionId={data.indeterminateFunctionId || ''}
                onFunctionSelected={async (func) => {
                  if (func !== EmptyId) {
                    await client.modules.entryPoints.updateIndeterminateFunctionId(
                      snapshotData.snapshot.id,
                      moduleId,
                      entryPoint.id,
                      data.id,
                      func,
                    );
                    data.indeterminateFunctionId = func;
                  }
                }}
              />
            </div>
          }
          confirmButton={{
            label: <React.Fragment>Save</React.Fragment>,
            type: VisualCategory.Success,
            clicked: async () => {},
          }}
          cancelButton={{
            label: 'Cancel',
            type: VisualCategory.Light,
            clicked: () => {},
          }}
        />,
      );
    }
  };

  return (
    <Patience showPatience={isSaving}>
      <Field inputId={nameId} label="Name" name="name" groupClass="col">
        <input
          id={nameId}
          type="text"
          className="form-control"
          value={name}
          disabled={isReadOnlyBot}
          onChange={(e) => setName(e.currentTarget.value)}
          onBlur={saveMetadata}
        />
      </Field>
      <Field inputId={descriptionId} label="Description" name="description" groupClass="col">
        <input
          id={descriptionId}
          type="text"
          className="form-control"
          value={description}
          disabled={isReadOnlyBot}
          onChange={(e) => setDescription(e.currentTarget.value)}
          onBlur={saveMetadata}
        />
      </Field>
      <label>Channels</label>
      {confirm.modal}
      <div
        style={{ pointerEvents: isReadOnlyBot ? 'none' : 'initial' }}
        className="entrypoint-channels ag-theme-balham mt-2"
      >
        <AgGridReact
          disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
          columnDefs={colDefs(deleteChannel, editChannel)}
          defaultColDef={{ cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' }, singleClickEdit: false }}
          rowData={entryPoint.channels}
          onCellValueChanged={save}
          rowHeight={36}
          suppressRowTransform={true}
          domLayout={'autoHeight'}
        ></AgGridReact>
      </div>
      <Button className={'mt-2'} isDisabled={isReadOnlyBot} type={VisualCategory.Primary} action={createChannel}>
        Create
      </Button>
    </Patience>
  );
};
