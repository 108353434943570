import { arrayRemove } from '@smartaction/common';
import { useClient, useSnapshot } from 'contexts';
import { Resource, ResourceType } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { useCheckTenantAccess } from '../AccessContext';
import { EntitlementNames } from '../../EntitlementNames';

const ResourcesContext = React.createContext<ResourcesContextType | undefined>(undefined);

type ResourcesContextType = {
  resources: Resource[];
  byType: Map<ResourceType, Resource[]>;
  deleteItem: (id: string) => void;
  refresh: (fromServer?: boolean) => void;
  canManage?: boolean;
};

export const ResourceProvider: React.FC = ({ children }) => {
  const snapshot = useSnapshot();
  const client = useClient('resources');
  const [resources, setResources] = useState<Resource[]>([]);
  const canManage = useCheckTenantAccess(EntitlementNames.Resources.Manage);

  useEffect(() => {
    refresh(true);
  }, [snapshot]);

  const refresh = (fromServer?: boolean) => {
    if (fromServer) {
      client.getAll(snapshot.snapshot.id).then((res) => {
        if (res.success) {
          setResources(res.data!);
        }
      });
    } else {
      setResources(resources);
    }
  };

  const deleteItem = (id: string) => {
    const index = resources.findIndex((ci) => ci.id === id);
    client.delete(snapshot.snapshot.id, id).then((res) => {
      if (res.success) {
        setResources(arrayRemove(resources, index));
        refresh(true);
      }
    });
  };

  const resourcesByType = new Map<ResourceType, Resource[]>();

  const azureResources = resources.filter((r) => r.type === ResourceType.AzureLanguage);
  const twilioResources = resources.filter((r) => r.type === ResourceType.Twilio);
  const googleMapsResources = resources.filter(r => r.type === ResourceType.GoogleAddress);
  resourcesByType.set(ResourceType.AzureLanguage, azureResources);
  resourcesByType.set(ResourceType.Twilio, twilioResources);
  resourcesByType.set(ResourceType.GoogleAddress, googleMapsResources);

  return (
    <ResourcesContext.Provider value={{ resources, byType: resourcesByType, refresh, deleteItem, canManage }}>
      {children}
    </ResourcesContext.Provider>
  );
};

export const useResources = () => {
  const context = React.useContext(ResourcesContext);

  if (context === undefined) {
    throw new Error('useResources must be used within an ResourcesProvider!');
  }

  return context;
};
