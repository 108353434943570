import React, { useEffect, useState } from 'react';
import { useFlow } from './FlowContext';

const FilterContext = React.createContext<FilterContextType | undefined>(undefined);

type FilterContextType = {
  filter: string;
  filterIds: string[];
  matchesFilter: (id: string) => boolean;
  updateFilter: (filter: string) => void;
};
export enum FilterTypeEnum {
  Tag = 'tag:',
}

export const FilterProvider: React.FC = ({ children }) => {
  const { flow } = useFlow();
  const [filter, setFilter] = useState<string>('');
  const [filterIds, setFilterIds] = useState<string[]>([]);

  const checkIfIncludesFilterStr = (str: string, filterType?: FilterTypeEnum) => {
    const getCleanedString = (item: string) => item.replace(/\W/g, '').toLowerCase();
    if (filter?.length) {
      return getCleanedString(str).includes(
        filterType ? getCleanedString(filter.replace(`${filterType}`, '')) : getCleanedString(filter),
      );
    }
  };

  const flowModulesIdsByFilter = flow.modules.flatMap((mod) => {
    const moduleIds: string[] = [];
    checkIfIncludesFilterStr(mod.name) && moduleIds.push(mod.id); // Compare module name with filter.Add module id to list if matches
    flow.policies.flatMap(
      (policy) =>
        policy.ownerId === mod.id && checkIfIncludesFilterStr(policy.type) && moduleIds.push(mod.id, policy.id), // Compare policy type with filter.Add policy id,module id to list if matches
    );
    [...mod.blocks, ...mod.entryPoints].flatMap((node) => {
      node.steps.flatMap((step) => {
        flow.policies.flatMap(
          (policy) =>
            policy.ownerId === step.id &&
            checkIfIncludesFilterStr(policy.type) &&
            moduleIds.push(step.id, mod.id, node.id, policy.id), // Compare policy type with filter.Add policy id,step id,node id,module id to list if matches
        );
        step?.tags.flatMap(
          (tag) => checkIfIncludesFilterStr(tag, FilterTypeEnum.Tag) && moduleIds.push(step.id, node.id, mod.id), // Compare tag with filter.Add step id,node id,module id to list if matches
        );

        return (
          (checkIfIncludesFilterStr(step.name) ||
            checkIfIncludesFilterStr(step?.id) ||
            checkIfIncludesFilterStr(step.type)) &&
          moduleIds.push(step.id, node.id, mod.id) // Compare step name, id and type with filter.Add step id,node id,module id to list if matches
        );
      });
      return checkIfIncludesFilterStr(node.name) && moduleIds.push(node.id, mod.id); // Compare node name with filter.Add node id,module id to list if matches
    });
    return [...new Set(moduleIds)];
  });

  const flowFunctionsIdsByFilter = flow.functions.flatMap((func) => {
    const funcIds: string[] = [];
    checkIfIncludesFilterStr(func.name) && funcIds.push(func.id); // Compare func name with filter.Add func id to list if matches
    flow.policies.flatMap(
      (policy) =>
        policy.ownerId === func.id && checkIfIncludesFilterStr(policy.type) && funcIds.push(func.id, policy.id), // Compare policy type with filter.Add policy id,func id to list if matches
    );
    func.steps.flatMap((step) => {
      flow.policies.flatMap(
        (policy) =>
          policy.ownerId === step.id &&
          checkIfIncludesFilterStr(policy.type) &&
          funcIds.push(step.id, func.id, policy.id), // Compare policy type with filter.Add policy id,step id,func id to list if matches
      );

      return (
        (checkIfIncludesFilterStr(step.name) || checkIfIncludesFilterStr(step.type)) && funcIds.push(step.id, func.id) // Compare step name and type with filter.Add step id,func id to list if matches
      );
    });
    return [...new Set(funcIds)];
  });

  useEffect(() => {
    const ids = [
      //List of ids that matches filter
      flowModulesIdsByFilter,
      flowFunctionsIdsByFilter,
    ]
      .filter(Boolean)
      .flat();
    setFilterIds(ids);
  }, [filter, flow]);

  const matchesFilter = (id: string) => filterIds.includes(id);
  const updateFilter = (filter: string) => setFilter(filter);

  return (
    <FilterContext.Provider value={{ filter, filterIds, matchesFilter, updateFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = React.useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within an FilterProvider!');
  }
  return context;
};
