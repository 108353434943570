import React from 'react';
import { PolicyModalProps } from '../../Types';
import { BargeInPolicy } from 'internal/models';
import { IntentOption, useBargeInPolicyLogic } from './useBargeInPolicyLogic';
import { Button, Collapsible, Input, Checkbox, Patience, Field, Select } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Table } from '../../../../table';
import { BehaviorControl } from '../../behaviors/BehaviorControl';
import { dtmfOptions, allowedBehaviors } from './dataGenerator';
import ReactSelect from 'react-select';

export const BargeInPolicyView: React.FC<PolicyModalProps<BargeInPolicy>> = ({ policy, disabled }) => {
  const { data, isLoading, intentsOptions, ...cb } = useBargeInPolicyLogic(policy);

  return (
    <div className={'barge-in-policies-container'}>
      <Patience showPatience={isLoading} />
      <div>
        <Checkbox readonly={disabled} value={policy.enabled} setValue={cb.onChangeEnabled} label="Is Enabled" />
        <Checkbox
          readonly={disabled}
          value={policy.excludeParents}
          setValue={cb.onChangeExcludeParents}
          label="Exclude Parents"
        />
      </div>
      {data.map((p, bargeInIndex) => {
        return (
          <Collapsible
            labelContent={
              <Input
                disabled={disabled}
                value={p.name}
                onChange={(value) => cb.onChangePolicyName(bargeInIndex, value)}
                className={'barge-in-policy-input-name'}
              />
            }
            key={p.id}
            extraControls={
              <Button
                type={VisualCategory.Danger}
                action={() => cb.onDeleteBargeIn(bargeInIndex)}
                className={'barge-in-policy-btn-delete'}
                isDisabled={disabled}
              >
                <Icon type={IconType.Delete} />
              </Button>
            }
          >
            <Field inputId={`dtmf-${p.id}`} label={'DTMF key'}>
              <Select
                options={dtmfOptions}
                onChange={(value) => cb.onChangeDtmfKey(bargeInIndex, value)}
                id={`dtmf-${p.id}`}
                disabled={disabled}
                value={p.dtmfKey || ''}
                style={{ maxWidth: 130 }}
                className={'mt-1'}
              />
            </Field>

            <Field inputId={`intents-${p.id}`} label={'Intents'} groupClass={'my-2 barge-in-policy-field'}>
              <ReactSelect
                isMulti
                // @ts-ignore options type
                options={intentsOptions}
                value={p.intents}
                isDisabled={disabled}
                onChange={(selectedIntents) =>
                  cb.onChangeIntents({ bargeInIndex, selectedIntents: selectedIntents as unknown as IntentOption[] })
                }
              />
            </Field>

            <div style={{ pointerEvents: disabled ? 'none' : 'initial' }}>
              <Table
                id={`table-${p.id}`}
                title={'Keywords'}
                headersGroup={['Code', 'Keywords']}
                columnsSize={['sm']}
                rows={p.rows}
                onDeleteRow={(_, triggerIndex) => cb.onDeleteTrigger({ bargeInIndex, triggerIndex })}
                footer={
                  <Button
                    isDisabled={disabled}
                    action={() => cb.onCreateTrigger(bargeInIndex)}
                    type={VisualCategory.Primary}
                  >
                    <Icon type={IconType.Add} /> Trigger
                  </Button>
                }
              />
            </div>

            <BehaviorControl
              disabled={disabled}
              allowedTypes={allowedBehaviors}
              behavior={p.behavior}
              update={(b) => cb.onChangeBehavior(bargeInIndex, b)}
            />
          </Collapsible>
        );
      })}
      <div>
        <Button isDisabled={disabled} action={cb.onCreateBargeIn} type={VisualCategory.Primary}>
          <Icon type={IconType.Add} /> Barge-In
        </Button>
      </div>
    </div>
  );
};
