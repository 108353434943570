import React, { useEffect, useState } from 'react';
import { useClient } from '../ClientContext';
import { ILanguage } from 'internal/clients/design/resources/ILanguage';

const LanguageContext = React.createContext<LanguageContextType | undefined>(undefined);

export type LanguageContextType = {
  languageCodes: string[];
  languages: Map<string, ILanguage>;
};

export const LanguageProvider: React.FC = ({ children }) => {
  const client = useClient('resources').languages;
  const [languageCodes, setLanguageCodes] = useState<string[]>([]);
  const [languages, setLanguages] = useState<Map<string, ILanguage>>(new Map());
  
  useEffect(() => {
    const getLanguages = async () => {
      try {
        const response = await client.getLanguages();
        
        if (response && response.data) {
          const languagesArray = response.data;
          
          const languagesMap = new Map<string, ILanguage>();
          languagesArray.forEach(lang => {
            languagesMap.set(lang.code, lang);
          });
          setLanguages(languagesMap);
          setLanguageCodes(Array.from(languagesMap.keys()));
        } else {
          console.error('Language data was not received');
        }
      } catch (error) {
        console.error('Failed to get languages:', error);
      }
    };
    
    getLanguages();
  }, [client]);
  
  const value = { languages, languageCodes };
  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useLanguages = () => {
  const context = React.useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider!');
  }
  return context;
};