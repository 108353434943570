import { Access } from 'internal/models/Access';
import React, { useEffect, useState } from 'react';
import { useClient } from "./ClientContext";
import { useTenant } from './TenantContext';

const AccessContext = React.createContext<Access | undefined>(undefined);

export const AccessProvider: React.FC = ({children}) => {
    const [access, setAccess] = useState<Access>(new Access(false, [], new Map()));
    const client = useClient('access');

    useEffect(() => {
        client.getAccessAsync().then(access => {
            if (access === undefined) {
                console.error("Access was returned with no result");
                return;
            }
            setAccess(access);
        });
    }, []);

    return (
        <AccessContext.Provider value={access}>
            {children}
        </AccessContext.Provider>
    );
}

export const useAccess = () => {
    const context = React.useContext(AccessContext);

    if (context === undefined) {
        throw new Error("useAccess must be used within an AccessProvider!");
    }

    return context;
}

/** Can pass in a bunch of entitlements to look for. Will return an array of bools with matching indices to the entitlements provided.
 *  so for a user with the 'canEdit' and 'canDelete' entitlements, passing in ['canEdit', 'canView', 'canMove', 'canDelete'] should return [true, false, false, true].
 */
export const useCheckTenantAccessArray =  (requiredEntitlements: string[]) => {
    const tenant = useTenant();
    const context = React.useContext(AccessContext);

    if (context === undefined) {
        throw new Error("useCheckTenantAccess must be used within an AccessProvider!");
    }

    const result = requiredEntitlements.map(e => context.fullAccess);

    if (context.fullAccess) {
        return result;
    }

    if (!tenant) {
        return result;
    }

    const tenantEntitlements = context.tenantEntitlements.get(tenant.id);

    // basically, we got in an array of entitlements we're looking for. We loop through the available entitlements, then check it against the passed in array.
    // anytime we get a match, we have an index, and we set the value at that position to true.
    if (tenantEntitlements === undefined) {
        return result;
    }

    for(let i = 0; i < tenantEntitlements.length; i++) {
        const entitlement = tenantEntitlements[i];
        const foundIdx = requiredEntitlements.findIndex(e => e === entitlement);

        if (foundIdx > -1) {
            result[foundIdx] = true;
        }
    }

    return result;
}

export const useCheckTenantAccess = (requiredEntitlement: string) => {
    const tenant = useTenant();
    const context = React.useContext(AccessContext);

    if (context === undefined) {
        throw new Error("useCheckTenantAccess must be used within an AccessProvider!");
    }

    if (context.fullAccess) {
        return true;
    }

    if (!tenant) {
        return false;
    }

    const tenantEntitlements = context.tenantEntitlements.get(tenant.id);

    if (tenantEntitlements === undefined) {
        return false;
    }

    return tenantEntitlements.some(t => t === requiredEntitlement);
}