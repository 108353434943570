import { Toolbar } from '@smartaction/visuals';
import React, { useState } from 'react';

type ToolbarContextType = {
    toolbarContents?: React.ReactNode,
    setToolbarContents: (node?: React.ReactNode) => void
}

const ToolbarContext = React.createContext<ToolbarContextType | undefined>(undefined);

export const ToolbarProvider: React.FC = ({children}) => {
    const [toolbarContents, setToolbarContents] = useState<React.ReactNode>();

    const context = { toolbarContents, setToolbarContents };

    return (
        <ToolbarContext.Provider value={context}>
            {children}
            {toolbarContents ? <Toolbar title="Toolbar" startingSize={{height: 648, width: 310}}>{toolbarContents}</Toolbar> : <React.Fragment />}
        </ToolbarContext.Provider>
);
}


export const useSetToolbar = () => {
    const context = React.useContext(ToolbarContext);

    if (context === undefined) {
        throw new Error("useSetToolbar must be used within a ToolbarProvider!");
    }

    // Not allowing undefined here, because if they want to clear the contents, that's useClearToolbar.
    return (node: React.ReactNode) => context.setToolbarContents(node);
}

export const useClearToolbar = () => {
    const context = React.useContext(ToolbarContext);

    if (context === undefined) {
        throw new Error("useSetToolbar must be used within a ToolbarProvider!");
    }

    return () => context.setToolbarContents(undefined);
}