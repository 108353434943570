import { Methods, ValidateResponseActionEnum } from 'internal/clients/Methods';
import { DataResponse, ServerResponse } from 'internal/clients/Responses';
import {
  AddressInputClient,
  CLUClient,
  DTMFClient,
  IAddressInputClient,
  ICLUClient,
  IDTMFClient,
  INumericInputClient,
  NumericInputClient,
} from './input';
import {
  CallFunctionClient,
  GoToClient,
  ICallFunctionClient,
  IGoToClient,
  IIncrementClient,
  IScriptClient,
  IncrementClient,
  ScriptClient,
  SetLocationClient,
  ISetLocationClient,
  TriggerAgentClient,
  ITriggerAgentClient,
  WaitForAgentClient,
  IWaitForAgentClient,
} from './process';
import { ForEachClient, IfClient, IForEachClient, IIfClient, IWhileClient, WhileClient } from './conditional';
import { IPhoneTransferClient, PhoneTransferClient } from './finish';
import { ISendSMSClient, SendSMSClient } from './output';
import {
  ICallAPIClient,
  CallApiClient,
  IDefineObjectClient,
  ISetValuesClient,
  DefineObjectClient,
  SetValuesClient,
} from './data';
import { IStepClient } from './IStepClient';
import { Container, Output, Pointer, StepType } from 'internal/models';
import { ISetLanguageClient } from './process/ISetLanguageClient';
import { SetLanguageClient } from './process/SetLanguageClient';

export class StepClient implements IStepClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
    this.clus = new CLUClient(this.serviceUrl, this.methods);
    this.ifs = new IfClient(this.serviceUrl, this.methods);
    this.goTos = new GoToClient(this.serviceUrl, this.methods);
    this.transfers = new PhoneTransferClient(this.serviceUrl, this.methods);
    this.callAPIs = new CallApiClient(this.serviceUrl, this.methods);
    this.scripts = new ScriptClient(this.serviceUrl, this.methods);
    this.dtmfs = new DTMFClient(this.serviceUrl, this.methods);
    this.numericInputs = new NumericInputClient(this.serviceUrl, this.methods);
    this.addressInputs = new AddressInputClient(this.serviceUrl, this.methods);
    this.forEaches = new ForEachClient(this.serviceUrl, this.methods);
    this.whiles = new WhileClient(this.serviceUrl, this.methods);
    this.callFunctions = new CallFunctionClient(this.serviceUrl, this.methods);
    this.increments = new IncrementClient(this.serviceUrl, this.methods);
    this.defineObjects = new DefineObjectClient(this.serviceUrl, this.methods);
    this.setValues = new SetValuesClient(this.serviceUrl, this.methods);
    this.sendSMS = new SendSMSClient(this.serviceUrl, this.methods);
    this.setLanguages = new SetLanguageClient(this.serviceUrl, this.methods);
    this.setLocations = new SetLocationClient(this.serviceUrl, this.methods);
    this.triggerAgent = new TriggerAgentClient(this.serviceUrl, this.methods);
    this.waitForAgent = new WaitForAgentClient(this.serviceUrl, this.methods);
  }

  async updateName(snapshotId: string, stepId: string, name: string): Promise<ServerResponse> {
    const response = await this.methods.plainBodyRequest(`${this.getStepUrl(snapshotId, stepId)}/name`, 'PUT', name);

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Step Name`, response);
  }

  async updateDescription(snapshotId: string, stepId: string, description: string): Promise<ServerResponse> {
    const response = await this.methods.plainBodyRequest(
      `${this.getStepUrl(snapshotId, stepId)}/description`,
      'PUT',
      description,
    );

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Step Description`, response);
  }

  async updateTags(snapshotId: string, stepId: string, tags: string[]): Promise<ServerResponse> {
    const response = await this.methods.put(`${this.getStepUrl(snapshotId, stepId)}/tags`, tags);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Step Tags`, response);
  }

  async setSaveLocation(snapshotId: string, stepId: string, saveLocation: Pointer): Promise<ServerResponse> {
    const response = await this.methods.put(`${this.getStepUrl(snapshotId, stepId)}/save-location`, saveLocation);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Save Location`, response);
  }

  // There's a process that updates text if languages change. Rather than pop a bunch of save messages for every step in an open block, we allow callers to control whether to show it.
  async setOutputs(snapshotId: string, stepId: string, outputs: Output[]): Promise<ServerResponse> {
    const response = await this.methods.put(`${this.getStepUrl(snapshotId, stepId)}/outputs`, outputs);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Questions for an Input`,
      response,
    );
  }

  async setCanInterrupt(snapshotId: string, stepId: string, canInterrupt: boolean): Promise<ServerResponse> {
    const response = await this.methods.put(`${this.getStepUrl(snapshotId, stepId)}/can-user-interrupt`, canInterrupt);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Can User Interrupt`, response);
  }

  // See comments on interface for details (you can hover over the func name to see them), but don't use these endpoints with Ifs or CLUs.
  async createStep(
    snapshotId: string,
    stepId: string,
    type: StepType,
    name: string,
    index?: number,
  ): Promise<DataResponse<string>> {
    let url = `${this.getStepUrl(snapshotId, stepId)}/steps`;

    if (index !== undefined) {
      url += `?index=${index}`;
    }

    const request = {
      type: type,
      name: name,
    };

    const response = await this.methods.post(url, request);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Step in Step`,
      response,
    );
  }

  async reorderSteps(snapshotId: string, stepId: string, stepIds: string[]): Promise<ServerResponse> {
    const url = `${this.getStepUrl(snapshotId, stepId)}/steps`;
    const response = await this.methods.patch(url, stepIds);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Reordered} Steps in Step`, response);
  }

  async addExistingStep(
    snapshotId: string,
    parentStepId: string,
    stepId: string,
    container: Container,
    index?: number,
  ): Promise<ServerResponse> {
    let url = `${this.getStepUrl(snapshotId, parentStepId)}/steps/${stepId}`;
    if (index !== undefined) {
      url += `?index=${index}`;
    }

    const response = await this.methods.put(url, container);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Moved} Step to Step`, response);
  }

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1`;
  }

  private getStepUrl(snapshotId: string, stepId: string) {
    return `${this.getUrl(snapshotId)}/steps/${stepId}`;
  }

  async deleteAsync(snapshotId: string, stepId: string, container: Container): Promise<void> {
    let url = this.getUrl(snapshotId);
    switch (container.type) {
      case 'Block':
        url += `/modules/${container.parentId}/blocks/${container.containerId}/steps/${stepId}`;
        break;
      case 'EntryPoint':
        url += `/modules/${container.parentId}/entryPoints/${container.containerId}/steps/${stepId}`;
        break;
      case 'IfStep':
        url += `/steps/${container.parentId}/branches/${container.containerId}/steps/${stepId}`;
        break;
      case 'CLUStep':
        url += `/steps/${container.parentId}/intents/${container.containerId}/steps/${stepId}`;
        break;
      case 'Function':
        url += `/functions/${container.containerId}/steps/${stepId}`;
        break;
      default:
        console.error(`Deleting from ${container.type} has not been implemented`);
    }

    const response = await this.methods.delete(url);
    // throws an exception if this fails.
    await this.methods.handleErrors('Deleting Step', response);
  }

  readonly clus: ICLUClient;
  readonly ifs: IIfClient;
  readonly goTos: IGoToClient;
  readonly transfers: IPhoneTransferClient;
  readonly callAPIs: ICallAPIClient;
  readonly scripts: IScriptClient;
  readonly dtmfs: IDTMFClient;
  readonly numericInputs: INumericInputClient;
  readonly addressInputs: IAddressInputClient;
  readonly forEaches: IForEachClient;
  readonly whiles: IWhileClient;
  readonly callFunctions: ICallFunctionClient;
  readonly increments: IIncrementClient;
  readonly defineObjects: IDefineObjectClient;
  readonly setValues: ISetValuesClient;
  readonly sendSMS: ISendSMSClient;
  readonly setLanguages: ISetLanguageClient;
  readonly setLocations: ISetLocationClient;
  readonly triggerAgent: ITriggerAgentClient;
  readonly waitForAgent: IWaitForAgentClient;
}
