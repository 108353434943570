import { useObjects } from 'contexts';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { GroupedOption, Option } from 'ui/types';
import { ContextType, ContextTypeNames, SelectableContextTypes } from 'internal/models';
import { ValueType, OptionTypeBase } from "react-select/src/types";

type SelectTypeProps = {
  value: string;
  onChange: (value: string) => void;
  isDisabled: boolean;
};

export const SelectType: React.FC<SelectTypeProps> = ({ value, onChange, isDisabled }) => {
  const [options, setOptions] = useState<GroupedOption[]>([]);
  const objects = useObjects();
  const onChangeSelect = (value: ValueType<OptionTypeBase, false>): void => {
    onChange(value!.value);
  };
  useEffect(() => {
    const selectOptions: GroupedOption[] = [];
    selectOptions.push({
      label: 'Standard Types:',
      options: SelectableContextTypes.map((o) => {
        return {
          label: ContextTypeNames.get(o)!,
          value: o,
        };
      }),
    });
    if (objects.objects.length) {
      selectOptions.push({
        label: 'Objects:',
        options: objects.objects.map((o) => {
          return {
            label: o.name,
            value: o.id,
          };
        }),
      });
    }
    setOptions(selectOptions);
  }, [objects]);

  return (
    <Select<Option, false, GroupedOption>
      isDisabled={isDisabled}
      options={options}
      onChange={onChangeSelect}
      className={'manageApi-select-type'}
      styles={{
        menu: (baseStyles) => ({ ...baseStyles, width: '100%' }),
        control: (baseStyles) => ({
          ...baseStyles,
          borderStyle: undefined,
          borderWidth: undefined,
          borderRadius: undefined,
        }),
      }}
      value={{ label: ContextTypeNames.get(value as ContextType)! || ContextType.Object!, value: value }}
    />
  );
};
