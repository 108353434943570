import { Obj, StaticObjectList, Field, FieldType } from 'internal/models';
import { useClient, useSnapshot } from "contexts";
import React, { useEffect, useState } from "react";
import { arrayRemove } from '@smartaction/common';

const ObjectsContext = React.createContext<ObjectContextType | undefined>(undefined);

type ObjectContextType = {
    objects: Obj[],
    staticObjects: Obj[],
    map: Map<string, Obj>,
    deleteObj: (obj: Obj) => void,
    refresh: (fromServer?: boolean) => void
}

type ObjectProviderProps = {
    snapshotId: string
}

export const ObjectsProvider: React.FC<ObjectProviderProps> = ({snapshotId, children}) => {
    const snapshot = useSnapshot();
    const [objects, setObjects] = useState<Obj[]>([]);
    const [map, setMap] = useState<Map<string, Obj>>(new Map<string, Obj>());
    const client = useClient('objects');
    const staticObjects = StaticObjectList;

    useEffect(() => {
        refresh(true);
    }, [snapshot]);

    const refresh = (fromServer?: boolean) => {
        if (fromServer) {
            client.getAll(snapshot.snapshot.id).then(res => {
                if (res.success) {
                    processObjects(res.data!, true);
                }
            });
        } else {
            // by putting them in a new array, it'll change the reference and React will refresh, which will also catch any updates (like name changes)
            processObjects(objects, false);
        }
    };

    const deleteObj = (obj: Obj) => {
        const index = objects.findIndex(ci => ci === obj);
        client.delete(snapshot.snapshot.id, obj.id).then(res => {
            if (res.success) {
                const items = arrayRemove(objects, index);
                processObjects(items, false);
                refresh(true);
            }
        });

    }

    const processObjects = (objs: Obj[], useStatic: boolean) => {
        if(useStatic){
            objs = StaticObjectList.concat(objs);
        }

        const map = new Map<string, Obj>();
        for(let ctxItem of objs) {
            map.set(ctxItem.id, ctxItem);
        }

        setMap(map);
        setObjects([...objs]);
    }

    return (
        <ObjectsContext.Provider value={{objects, map, staticObjects, refresh, deleteObj}}>
            {children}
        </ObjectsContext.Provider>
    );
}

export const useObjects = () => {
    const context = React.useContext(ObjectsContext);

    if (context === undefined) {
        throw new Error("useObjects must be used within an ObjectsProvider!");
    }

    return context;
}
