import { VisualCategory } from '@smartaction/styles';
import { Button, Editor } from '@smartaction/visuals';
import { useClient } from 'contexts/ClientContext';
import { useBots, useContextInUse, useContextItems } from 'contexts';
import { useSnapshot } from 'contexts/SnapshotContext';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, RowDragEvent } from 'ag-grid-community';
import { ServerResponse } from 'internal/clients';
import { toast } from 'react-toastify';
import { ContextItem, ContextType } from 'internal/models';
import { RegExps } from 'ui/constants';
import { ContextItemInUse, CtxManagerColDefs } from './ColumnDefinitions';
import { EntitlementNames } from 'EntitlementNames';
import { useCheckTenantAccess } from 'contexts/AccessContext';

export const ContextManager: React.FC = () => {
  const snapshot = useSnapshot();
  const contextItems = useContextItems();
  const contextInUse = useContextInUse();
  const client = useClient('context');
  const gridRef = useRef<any>();

  const [rowData, setRowData] = useState<ContextItemInUse[]>();
  const [staticRowData, setStaticRowData] = useState<ContextItemInUse[]>();
  
  const { isReadOnlyBot } = useBots();
  const canManage = useCheckTenantAccess(EntitlementNames.Context.Manage);
  const isReadOnly = isReadOnlyBot || !canManage;

  useEffect(() => {
    let freshContextItems = contextItems.contextItems
      .filter((x) => !x.isStatic)
      .map((item) => {
        return { item: item, inUse: contextInUse.includes(item.id) };
      });

    setRowData(freshContextItems);

    let staticContextItems = contextItems.contextItems
      .filter((x) => x.isStatic)
      .map((item) => {
        return { item: item, inUse: contextInUse.includes(item.id) };
      });

    setStaticRowData(staticContextItems);
  }, [snapshot, contextItems]);

  const onRowDragMove = useCallback(
    (event: RowDragEvent<ContextItemInUse>) => {
      console.log(event);
      var movingNode = event.node;
      var overNode = event.overNode;

      if (event.overIndex === -1) return;

      if (event.node.data?.item && event.node.data.item.isStatic) return;

      if (event.overNode?.data?.item.isStatic) return;

      let rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove && rowData && overNode) {
        // the list of rows we have is data, not row nodes, so extract the data
        let movingData = movingNode.data;
        let overData = overNode!.data;

        let fromIndex = rowData.indexOf(movingData!);
        let toIndex = rowData.indexOf(overData!);
        let newStore = rowData.slice();
        moveInArray(newStore, fromIndex, toIndex);

        setRowData(newStore);

        function moveInArray(arr: ContextItemInUse[], fromIndex: number, toIndex: number) {
          let element = arr[fromIndex];
          arr.splice(fromIndex, 1);
          arr.splice(toIndex, 0, element);
        }
      }
    },
    [rowData],
  );

  const onRowDragEnd = useCallback(
    (event: RowDragEvent<ContextItemInUse>) => {
      let draggedCtxItem = event.node.data?.item;
      if (event.overIndex === -1) {
        contextItems.refresh();
        return;
      }

      if (event.overNode?.data?.item.isStatic) return;

      if (draggedCtxItem && !draggedCtxItem.isStatic) {
        client.updateOrder(snapshot.snapshot.id, draggedCtxItem.id, event.overIndex).then((res) => {
          if (res.success) {
            contextItems.refresh(true);
          }
        });
      }
    },
    [rowData],
  );

  const handleSaveEvent = (event: CellValueChangedEvent<ContextItemInUse>) => {
    const columnDef = event.colDef;
    const ctxItem = event.data;
    const { newValue, oldValue } = event;
    const snapshotId = snapshot.snapshot.id;
    const validNameValue = RegExps.letterAndNumberWithUnderscore.test(event.newValue);

    switch (columnDef.field) {
      case 'item.name':
        if (!event.newValue) {
          toast('Name must be set! Changes are not saved.', { type: 'error' });
          return;
        } else if (!validNameValue) {
          toast('Name cannot contain special characters.! Changes are not saved.', { type: 'error' });
          ctxItem.item.name = oldValue;
          contextItems.refresh();
        } else {
          finishSave(client.updateName(snapshotId, ctxItem.item.id, newValue), () => (ctxItem.item.name = newValue));
        }
        break;
      case 'item.category':
        finishSave(
          client.updateCategory(snapshotId, ctxItem.item.id, newValue),
          () => (ctxItem.item.category = newValue),
        );
        break;
      case 'item.description':
        finishSave(
          client.updateDescription(snapshotId, ctxItem.item.id, newValue),
          () => (ctxItem.item.description = newValue),
        );
        break;
      // No type case, as it gets changed by the following method instead
      case 'item.isList':
        finishSave(client.updateIsList(snapshotId, ctxItem.item.id, newValue), () => (ctxItem.item.isList = newValue));
        break;
      case 'item.isSensitive':
        if (newValue === true && ctxItem.item.isReporting) {
          ctxItem.item.isReporting = false;
        }
        finishSave(
          client.updateIsSensitive(snapshotId, ctxItem.item.id, newValue),
          () => (ctxItem.item.isSensitive = newValue),
        );
        break;
      case 'item.isReporting':
        finishSave(
          client.updateIsReporting(snapshotId, ctxItem.item.id, newValue),
          () => (ctxItem.item.isReporting = newValue),
        );
        break;
      case 'item.isPublic':
        finishSave(
          client.updateIsPublic(snapshotId, ctxItem.item.id, newValue),
          () => (ctxItem.item.isPublic = newValue),
        );
        break;
    }
  };

  const saveType = (contextItem: ContextItem, newType: ContextType, newTypeId?: string) => {
    finishSave(client.updateType(snapshot.snapshot.id, contextItem.id, newType, newTypeId), () => {
      contextItem.type = newType;
      contextItem.typeId = newTypeId;
    });
  };

  const finishSave = async (responsePromise: Promise<ServerResponse>, localUpdate: () => void) => {
    const response = await responsePromise;
    if (response.success) {
      localUpdate();
      contextItems.refresh();
    }
  };

  const create = () => {
    const baseName = 'ContextItem';
    let increment = 1;
    let defaultName = () => baseName.concat(increment.toString());
    while (contextItems.contextItems.some((x) => x.name === defaultName())) {
      increment++;
      if (increment > 99) {
        toast('Maximum number of default names has exceeded.', { type: 'error' });
        return;
      }
    }
    return contextItems.addItem(defaultName());
  };

  return (
    <React.Fragment>
      <Editor>
        <div className="ag-theme-balham" style={{ height: 300 }}>
          <AgGridReact
            disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
            columnDefs={CtxManagerColDefs(
              !isReadOnly,
              true,
              (ctx, type) => {},
              (i) => {},
            )}
            defaultColDef={{ cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' }, sortable: true }}
            rowData={staticRowData}
            groupDefaultExpanded={1}
            rowHeight={38}
            suppressRowTransform={true}
            stopEditingWhenCellsLoseFocus={true}
          ></AgGridReact>
        </div>
        <div className="ag-theme-balham" style={{ height: 400 }}>
          <AgGridReact
            rowDragManaged={false}
            disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
            columnDefs={CtxManagerColDefs(!isReadOnly, false, saveType, (i) => contextItems.deleteItem(i.item))}
            defaultColDef={{ cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' }, sortable: true }}
            rowData={rowData}
            onCellValueChanged={handleSaveEvent}
            groupDefaultExpanded={1}
            onRowDragMove={onRowDragMove}
            onRowDragEnd={onRowDragEnd}
            rowHeight={38}
            suppressRowTransform={true}
            stopEditingWhenCellsLoseFocus={true}
          ></AgGridReact>
        </div>
        <Button action={create} type={VisualCategory.Primary}>
          Create
        </Button>
      </Editor>
    </React.Fragment>
  );
};
