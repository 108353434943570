import { Color, VisualCategory } from '@smartaction/styles';
import { useConfigs, useContextItems, useLocationDefinitions } from 'contexts';
import {
  ConfigPointer,
  ContextPointer,
  CurrentDateTimePointer,
  DirectAssignmentListPointer,
  DirectAssignmentPointer,
  DirectAssignmentDateTimePointer,
  Pointer,
  PointerType,
  DateTimePointerValue,
  DateTimePointerValueType,
  DateTimeValue,
  DateValue,
  TimeValue,
  DayOfMonthValue,
  DayOfWeekValue,
  LocationPointer,
  IterationPointer,
} from 'internal/models';
import React, { useEffect } from 'react';
import { DateTimePointerOptions } from 'ui/components/design/pointers/DateTimePointerEditor';

type PointerRendererProps<T extends Pointer> = {
  pointer: T;
};

export const PointerRenderer: React.FC<PointerRendererProps<Pointer>> = ({ pointer }: { pointer: Pointer }) => {
  if (!pointer) {
    return <React.Fragment />;
  }
  switch (pointer.type) {
    case PointerType.Config:
      return <ConfigPointerRenderer pointer={pointer as ConfigPointer} />;
    case PointerType.Context:
      return <ContextPointerRenderer pointer={pointer as ContextPointer} />;
    case PointerType.DirectAssignment:
      return <DirectAssignmentPointerRenderer pointer={pointer as DirectAssignmentPointer} />;
    case PointerType.DirectAssignmentDateTime:
      return <DirectAssignmentDateTimePointerRenderer pointer={pointer as DirectAssignmentDateTimePointer} />;
    case PointerType.DirectAssignmentList:
      return <DirectAssignmentListPointerRenderer pointer={pointer as DirectAssignmentListPointer} />;
    case PointerType.CurrentDateTime:
      return <DateTimePointerRenderer pointer={pointer as CurrentDateTimePointer} />;
    case PointerType.Location:
      return <LocationPointerRenderer pointer={pointer as LocationPointer} />;
    case PointerType.Iteration:
      return <IterationPointerRenderer pointer={pointer as IterationPointer} />;
    case PointerType.Empty:
      return <span className={Color(VisualCategory.Dark)}>None selected</span>;
  }
  return <React.Fragment />;
};

const ConfigPointerRenderer: React.FC<PointerRendererProps<ConfigPointer>> = ({ pointer }) => {
  const configs = useConfigs();
  const config = configs.all.get(pointer.configId);
  if (!config) {
    return <React.Fragment>Cannot determine pointer type</React.Fragment>;
  }

  return (
    <React.Fragment>
      {config.name} <span className="badge bg-primary">Config</span>
    </React.Fragment>
  );
};
const LocationPointerRenderer: React.FC<PointerRendererProps<LocationPointer>> = ({ pointer }) => {
  const { locationDefinitions, commonLocationFieldIds } = useLocationDefinitions();

  const name =
    locationDefinitions[0]?.customFields.find((fieldId) => fieldId.id === pointer.fieldId)?.name ||
    Object.entries(commonLocationFieldIds).find(([key, val]) => val === pointer.fieldId)?.[0];
  if (!name) {
    return <React.Fragment>Cannot determine location pointer type</React.Fragment>;
  }

  return (
    <React.Fragment>
      {name} <span className="badge bg-primary">Location</span>
    </React.Fragment>
  );
};

const DateTimePointerRenderer: React.FC<PointerRendererProps<CurrentDateTimePointer>> = ({ pointer }) => {
  const item = DateTimePointerOptions.find(
    (option: { label: string | undefined }) => option.label === pointer.currentDateTimeCheck,
  );
  const value = item ? item.label : <i>Undetermined</i>;
  return (
    <React.Fragment>
      {value} <span className="badge bg-success">Date/Time</span>
    </React.Fragment>
  );
};

const ContextPointerRenderer: React.FC<PointerRendererProps<ContextPointer>> = ({ pointer }) => {
  const contextItems = useContextItems();
  const item = contextItems.map.get(pointer.contextItemId);
  const name = item ? item.name : <i>Undetermined</i>;
  useEffect(() => {
    // Need to be refreshed to get the latest context items
    contextItems.refresh(true);
  }, []);
  return (
    <React.Fragment>
      {name} <span className="badge bg-success">Context</span>
    </React.Fragment>
  );
};

const DirectAssignmentPointerRenderer: React.FC<PointerRendererProps<DirectAssignmentPointer>> = ({ pointer }) => {
  return (
    <React.Fragment>
      {pointer.value} <span className="badge bg-dark">Hardcoded</span>
    </React.Fragment>
  );
};

const DirectAssignmentDateTimePointerRenderer: React.FC<PointerRendererProps<DirectAssignmentDateTimePointer>> = ({
  pointer,
}) => {
  let getDataValue = (dateValue: DateTimePointerValue) => {
    if (DateTimePointerValueType.DateTimeValue === dateValue.type) {
      return (dateValue as DateTimeValue).dateTime;
    }

    if (DateTimePointerValueType.DateValue === dateValue.type) return (dateValue as DateValue).date;
    if (DateTimePointerValueType.TimeValue === dateValue.type) return (dateValue as TimeValue).time;
    if (DateTimePointerValueType.DayOfMonthValue === dateValue.type) return (dateValue as DayOfMonthValue).dayOfMonth;
    if (DateTimePointerValueType.DayOfWeekValue === dateValue.type) return (dateValue as DayOfWeekValue).dayOfWeek;
    if (DateTimePointerValueType.Empty === dateValue.type) return 'No Value';
  };

  return (
    <React.Fragment>
      {getDataValue(pointer.value)}
      <span className="badge bg-dark">Hardcoded</span>
    </React.Fragment>
  );
};

const DirectAssignmentListPointerRenderer: React.FC<PointerRendererProps<DirectAssignmentListPointer>> = ({
  pointer,
}) => {
  const content = pointer.values ? (
    <span>
      # of values: {pointer.values.length} <span className="badge bg-dark">Hardcoded</span>
    </span>
  ) : (
    <i>No value</i>
  );
  return <React.Fragment>{content}</React.Fragment>;
};
const IterationPointerRenderer: React.FC<PointerRendererProps<IterationPointer>> = ({ pointer }) => {
  return (
    <React.Fragment>
      Current {pointer.type} <span className="badge bg-success">Iteration</span>
    </React.Fragment>
  );
};
