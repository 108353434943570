import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';

type LinkProps = {
    path: string,
    className?: string,
    style?: CSSProperties
}

export const Link: React.FC<LinkProps> = ({path, className, style, children}) => {
    const classes = className ? `nav-link ${className}` : "nav-link";
    return <NavLink className={({ isActive }) => isActive ? `active ${classes}` : classes} style={style} to={path} >{children}</NavLink>;
}