import { API, EmptyId } from 'internal/models';
import React, { useCallback, useEffect, useState } from 'react';
import { useClient } from '../ClientContext';
import { useSnapshot } from 'contexts/SnapshotContext';
import { ServerResponse } from '../../internal/clients';
const APIsContext = React.createContext<APIContextType | undefined>(undefined);
type APIContextType = {
  apis: API[];
  refresh: (fromServer?: boolean) => void;
  addItem: (name: string) => void;
  deleteItem: (id: string) => Promise<ServerResponse>;
  updateName: (id: string, name: string) => void;
  updateDescription: (id: string, description: string) => void;
  updateBaseUrlId: (id: string, baseUrlId: string) => void;
  getNameById: (id?: string) => string | null;
};

export const APIsProvider: React.FC = ({ children }) => {
  const snapshot = useSnapshot();
  const [apis, setAPIs] = useState<API[]>([]);
  const [idTranslations, setIdTranslations] = useState<{ [id: string]: string }>({ [EmptyId]: 'Empty' });

  const client = useClient('apis');

  const refresh = (fromServer?: boolean) => {
    if (fromServer) {
      client.getAPIsAsync(snapshot.snapshot.id).then((res) => {
        if(res.success){
          setAPIs(res.data!);
        }
      })
    }
  };

  const addItem = (name: string) => {
    const item = new API('', '', '', name, '', '', []);
    client.createAPIAsync(snapshot.snapshot.id, item.name, item.description).then((res) => {
      if (res.success) {
        item.id = res!.data!.id;
        const newApis = [...apis, item];
        setAPIs([...newApis]);
      }
    });
  };

  const deleteItem = async (id: string) => {
    const { success, error } = await client.deleteAPIAsync(snapshot.snapshot.id, id);
    if (success) {
      const newApis = apis.filter((item) => item.id !== id);
      setAPIs([...newApis]);
      return { success };
    }
    return Promise.reject({ error });
  };

  const updateName = (id: string, name: string) => {
    client.updateAPINameAsync(snapshot.snapshot.id, id, name).then((res) => {
      if (res.success) {
        const newApis = apis.map((item) => {
          if (item.id !== id) return item;

          item.name = name;

          return item;
        });

        setAPIs([...newApis]);
      }
    });
  };

  const updateDescription = (id: string, description: string) => {
    client.updateAPIDescriptionAsync(snapshot.snapshot.id, id, description).then((res) => {
      if (res.success) {
        const newApis = apis.map((item) => {
          if (item.id !== id) return item;

          item.description = description;
          return item;
        });

        setAPIs([...newApis]);
      }
    });
  };

  const updateBaseUrlId = (id: string, baseUrlId: string) => {
    client.updateBaseUrlIdAsync(snapshot.snapshot.id, id, baseUrlId).then((res) => {
      if (res.success) {
        const newApis = apis.map((item) => {
          if (item.id !== id) return item;

          item.baseUrlId = baseUrlId;
          return item;
        });

        setAPIs([...newApis]);
      }
    });

  }

  const getNameById = useCallback(
    (id?: string) => {
      if (!id) return null;
      return idTranslations[id] || null;
    },
    [idTranslations],
  );

  useEffect(() => {
    refresh(true);
  }, [snapshot]);

  useEffect(() => {
    const apisTranslations = apis.reduce((total, { id, name }) => {
      return {
        ...total,
        [id]: name,
      };
    }, {});
    setIdTranslations((prevState) => ({ ...prevState, ...apisTranslations }));
  }, [apis]);

  return (
    <APIsContext.Provider value={{ apis, refresh, addItem, deleteItem, updateName, updateDescription, getNameById, updateBaseUrlId }}>
      {children}
    </APIsContext.Provider>
  );
};
export const useAPIs = () => {
  const context = React.useContext(APIsContext);
  if (context === undefined) {
    throw new Error('useAPIs must be used within an APIsProvider!');
  }
  return context;
};
export const useAPI = (apiId: string) => {
    const context = React.useContext(APIsContext);
    if (context === undefined) {
        throw new Error("useAPI must be used within an APIsProvider!");
    }
    var api = context.apis?.find(o => o.id === apiId);
    return api;
}
