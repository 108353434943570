import { arrayRemove, ValidationErrors } from '@smartaction/common';
import { Field, useId, useComplexState, Input } from '@smartaction/visuals';
import { useBots, useClient, useSnapshot } from 'contexts';
import { Condition, DecisionBranch, PointerType } from 'internal/models';
import React, { useState, useEffect } from 'react';
import { useFlow } from 'contexts';
import { ConditionsTableAG } from '../../conditions/ConditionsTable-AG';

type DecisionBranchEditorProps = {
  moduleId: string;
  decisionId: string;
  branch: DecisionBranch;
};

const targetAllowedTypes = [PointerType.Config, PointerType.Context];
const sourceAllowedTypes = [
  PointerType.Config,
  PointerType.Context,
  PointerType.DirectAssignment,
  PointerType.DirectAssignmentList,
];

export const DecisionBranchEditor: React.FC<DecisionBranchEditorProps> = ({ moduleId, decisionId, branch }) => {
  const [name, setName] = useState(branch.name);
  const nameId = useId('blockName');
  const [validationErrors, updateValidationErrors] = useComplexState(new ValidationErrors(['name', 'description']));
  const { updateFlow } = useFlow();
  const client = useClient('flow');
  const { isReadOnlyBot} = useBots();

  const snapshotData = useSnapshot();

  const onChangeName = (value: string) => {
    validationErrors.resetLocal();
    if (!value.trim()) {
      updateValidationErrors(() => validationErrors.addLocalError('name', 'Name must not be empty'));
      return;
    }
    setName(value);
    if (!validationErrors.hasAnyErrors) {
      client.modules.decisions
        .updateBranchNameAsync(snapshotData.snapshot!.id, moduleId, decisionId, branch.id, value)
        .then((f) => {
          updateFlow(() => {
            branch.name = value;
          });
        });
    }
  };

  const addCondition = async (condition: Condition) => {
    const result = await client.modules.decisions.createConditionAsync(
      snapshotData.snapshot.id,
      moduleId,
      decisionId,
      branch.id,
      condition,
    );
    if (result) {
      condition.id = result.data!;
      updateFlow(() => {
        branch.conditions = [...branch.conditions, condition];
      });
    }
  };

  const onUpdateCondition = async (condition: Condition) => {
    const result = await client.modules.decisions.updateConditionAsync(
      snapshotData.snapshot.id,
      moduleId,
      decisionId,
      branch.id,
      condition.id,
      condition,
    );
    if (result) {
      updateFlow(() => {
        const index = branch.conditions.findIndex((c) => c.id === condition.id);
        branch.conditions[index] = condition;
        branch.conditions = [...branch.conditions];
      });
    }
  };

  const deleteCondition = async (conditionId: string) => {
    const result = await client.modules.decisions.deleteConditionAsync(
      snapshotData.snapshot.id,
      moduleId,
      decisionId,
      branch.id,
      conditionId,
    );
    if (result) {
      updateFlow(() => {
        const index = branch.conditions.findIndex((c) => c.id === conditionId);
        if (index > -1) {
          branch.conditions = arrayRemove(branch.conditions, index);
        }
      });
    }
  };

  useEffect(() => {
    setName(branch.name);
  }, [branch.name]);

  return (
    <div style={{ pointerEvents: isReadOnlyBot ? 'none' : 'initial' }} className="decision-branch-editor">
      <Field inputId={nameId} label="Name" name="name" groupClass="col" validationErrors={validationErrors}>
        <Input disabled={isReadOnlyBot} id={nameId} value={name} onChange={onChangeName} />
      </Field>
      <label className="mt-2">Conditions</label>
      <ConditionsTableAG
        conditions={branch.conditions}
        targetAllowedTypes={targetAllowedTypes}
        sourceAllowedTypes={sourceAllowedTypes}
        onAddCondition={addCondition}
        onUpdateCondition={onUpdateCondition}
        onDeleteCondition={deleteCondition}>
      </ConditionsTableAG>
    </div>
  );
};
