import React, { useEffect, useState } from 'react';
import { Button, PortalModal, SelectRenderer, useFromModal } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { useClient, useLocationDefinition, useLocationDefinitions } from 'contexts';
import { toast } from 'react-toastify';
import { CustomField, CustomFieldType } from 'internal/models';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import { CustomFieldTypeOptions } from './common';
import { ActionsRenderer } from 'ui/controls/grid/editors/ActionsRenderer';

const ManageCustomFieldsModal: React.FC = () => {
  const modal = useFromModal();
  const { refresh } = useLocationDefinitions();
  const { customFields, id } = useLocationDefinition();
  const [gridApi, setGridApi] = useState<GridApi>();
  const client = useClient('locationDefinition');

  const onDeleteRow = async (customField: CustomField) => {
    await client.customFields.delete(id, customField.id);
    refresh();
  };

  const header = {
    type: VisualCategory.Primary,
    content: <React.Fragment>Manage Custom Fields</React.Fragment>,
  };
  const save = async (event: CellValueChangedEvent) => {
    switch (event.colDef.field) {
      case 'name':
        if (!event.value) {
          toast('Name must be set! Changes are not saved.', { type: 'error' });
          return;
        } else {
          await client.customFields.updateName(id, event.data.id, `${event.value}`);
        }
        break;
      case 'description':
        if (!event.value) {
          toast('Name must be set! Changes are not saved.', { type: 'error' });
          return;
        } else {
          await client.customFields.updateDescription(id, event.data.id, `${event.value}`);
        }
        break;
      case 'type':
        await client.customFields.updateType(id, event.data.id, event.value as CustomFieldType);
        break;
    }
    refresh();
  };

  useEffect(() => {
    gridApi?.setRowData(customFields);
  }, [customFields]);
  const gridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api);
  };
  const colDefs = [
    { headerName: 'Name', field: 'name', editable: true, width: 130, resizable: true },
    { headerName: 'Description', field: 'description', editable: true, width: 180, resizable: true },
    {
      headerName: 'Type',
      field: 'type',
      cellRenderer: SelectRenderer,
      options: CustomFieldTypeOptions,
      editable: true,
      width: 120,
      resizable: true,
    },
    {
      headerName: 'Delete',
      cellRenderer: ActionsRenderer,
      width: 60,
      cellRendererParams: {
        actions: [
          {
            id: 'deleteButton',
            label: <Icon type={IconType.Delete} />,
            type: VisualCategory.Danger,
            action: onDeleteRow,
          },
        ],
      },
    },
  ];

  const content = (
    <div className="ag-theme-balham" style={{ maxHeight: '300px', minWidth: '500px', overflow: 'auto' }}>
      <AgGridReact
        disableStaticMarkup={true}
        columnDefs={colDefs}
        defaultColDef={{
          cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' },
          singleClickEdit: false,
        }}
        rowData={customFields}
        onGridReady={gridReady}
        onCellValueChanged={save}
        rowHeight={36}
        suppressRowTransform={true}
        stopEditingWhenCellsLoseFocus={true}
        domLayout="autoHeight"
      />
    </div>
  );

  const cancel = () => modal.forceClose();
  const saveClick = () => {
    modal.forceClose();
  };

  const buttons = (
    <React.Fragment>
      <Button className={'me-3'} action={saveClick} type={VisualCategory.Primary}>
        Save
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal className={'manage-custom-fields-modal'} header={header} content={content} buttons={buttons} />;
};
export default ManageCustomFieldsModal;
