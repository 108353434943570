import {
  ChannelType,
  ContextItem,
  ContextPointer,
  EmptyId,
  EmptyPointer,
  EntryPoint,
  Pointer,
  PointerControlFilters,
  PointerType,
  TriggerAgent,
  TriggerContextItem,
} from 'internal/models';
import React, { Fragment, useEffect, useState } from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { ProcessStepsColors } from '../../StepColorGroups';
import Select from 'react-select';
import { Option } from 'ui/types';
import { Button, Field } from '@smartaction/visuals';
import { PointerControl } from 'ui/components';

export const TriggerAgentStyling: StepStyling = {
  typeName: 'Trigger Agent',
  icon: IconType.TriggerAgent,
  ...ProcessStepsColors,
};

export const TriggerAgentStep: React.FC<TypedStepProps<TriggerAgent>> = ({ step, manipulateStep }) => {
  const bots = useBots();
  const { updateFlow } = useFlow();
  const client = useClient('flow');
  const contextClient = useClient('context');
  const triggerAgentClient = useClient('flow').steps.triggerAgent;
  const getBotById = (id: string) => bots.bots?.find((bot) => bot.id === id);
  const getContextItemName = (id: string) => secondAgentContexts.find((ctx) => ctx.id === id)?.name || '';
  const { snapshot } = useSnapshot();
  const [selectedAgentEntryPoints, setSelectedAgentEntryPoints] = useState<EntryPoint[]>([]);
  const [selectedAgentEntryPointsListOptions, setSelectedAgentEntryPointsListOptions] = useState<Option[]>([]);
  const [isLoadingEntryPoints, setIsLoadingEntryPoints] = useState(false);
  const [contextItems, setContextItems] = useState<TriggerContextItem[]>(step.contextItems);
  const [secondAgentContexts, setSecondAgentContexts] = useState<ContextItem[]>([]);

  const getAgentEntries = (botId: string) => {
    client.modules
      .getModuleAsync(botId)
      .then((modules) => {
        setIsLoadingEntryPoints(true);
        setSelectedAgentEntryPoints(modules.flatMap((module) => module.entryPoints));
      })
      .finally(() => {
        getAgentContexts(botId);
        setIsLoadingEntryPoints(false);
      });
  };

  useEffect(() => {
    const bot = getBotById(step.botId);
    if (step.botId && bot?.unpublished?.id) {
      getAgentEntries(bot.unpublished.id);
      getAgentContexts(step.botId);
    }
  }, [step.botId]);

  useEffect(() => {
    setSelectedAgentEntryPointsListOptions(
      selectedAgentEntryPoints.map((ep) => ({
        label: ep.name,
        value: ep.originalId,
      })),
    );
  }, [selectedAgentEntryPoints]);

  const agentOptions = bots.bots
    ? bots.bots.map((bot) => {
        return { label: bot.name, value: bot.id };
      })
    : [];

  const getAgentContexts = (id: string) =>
    contextClient.getContexts(id).then((res) => {
      if (res.success && res.data) {
        setSecondAgentContexts(res.data.filter((item) => item.isPublic));
      }
    });
  const onAgentSelect = (val: Option) => {
    const bot = getBotById(val.value);
    if (bot && bot.unpublished) {
      getAgentEntries(bot.unpublished.id);
      getAgentContexts(val.value);
    }
    updateFlow(() => {
      step.botId = val.value;
      step.entryPointOriginalId = '';
      step.channelType = ChannelType.SMS;
    });
  };
  const onEntryPointSelect = (val: Option) => {
    if (val?.value && step?.channelType) {
      triggerAgentClient
        .setTriggerChannel(snapshot.id, step.id, {
          botId: step?.botId,
          entryPointOriginalId: val?.value,
          channelType: step?.channelType as ChannelType,
        })
        .then(() =>
          updateFlow(() => {
            step.entryPointOriginalId = val.value;
          }),
        );
    }
  };

  const onChannelSelect = (val: Option) => {
    if (step.entryPointOriginalId) {
      triggerAgentClient
        .setTriggerChannel(snapshot.id, step.id, {
          botId: step?.botId,
          entryPointOriginalId: step?.entryPointOriginalId,
          channelType: val?.value as ChannelType,
        })
        .then(() =>
          updateFlow(() => {
            step.channelType = val.value as ChannelType;
          }),
        );
    }
  };

  const onDestinationSelect = (destination: Pointer) => {
    if (destination.type !== PointerType.Empty) {
      const destinationId = (destination as ContextPointer).contextItemId;
      triggerAgentClient.setTriggerDestination(snapshot.id, step.id, destinationId).then(() =>
        updateFlow(() => {
          step.destinationId = destinationId;
        }),
      );
    }
  };

  const changeContextItems = (pointer: Pointer, index: number) => {
    const updatedCtxItems = contextItems.map((contextItem, cIndex) =>
      index === cIndex
        ? {
            ...contextItem,
            originalPointer: pointer,
          }
        : contextItem,
    );
    setContextItems(updatedCtxItems);
    if (contextItems[index].originalPointer.type === PointerType.Empty) {
      triggerAgentClient
        .setTriggerContextItem(snapshot.id, step.id, {
          secondAgentContextItemOriginalId: contextItems[index].secondAgentContextItemOriginalId,
          originalPointer: pointer,
        })
        .then(() =>
          updateFlow(() => {
            step.contextItems = updatedCtxItems;
          }),
        );
    } else {
      triggerAgentClient
        .setTriggerContextItemPointer(
          snapshot.id,
          step.id,
          contextItems[index].secondAgentContextItemOriginalId,
          pointer,
        )
        .then(() =>
          updateFlow(() => {
            step.contextItems = updatedCtxItems;
          }),
        );
    }
  };

  const addContextItem = () => {
    setContextItems([
      ...contextItems,
      {
        secondAgentContextItemOriginalId: '',
        originalPointer: new EmptyPointer(),
      },
    ]);
  };
  const deleteContextItem = (currentIndex: number) => {
    const newContextItems = contextItems.filter((x, index) => index !== currentIndex && x);
    setContextItems([...newContextItems]);
    contextItems[currentIndex].secondAgentContextItemOriginalId &&
      triggerAgentClient
        .deleteTriggerContextItem(snapshot.id, step.id, contextItems[currentIndex].secondAgentContextItemOriginalId)
        .then(() =>
          updateFlow(() => {
            step.contextItems = newContextItems;
          }),
        );
  };

  const onSecondAgentContextOptionChange = (item: Option, index: number) => {
    const updatedCtxItems = contextItems.map((contextItem, cIndex) =>
      index === cIndex
        ? {
            ...contextItem,
            secondAgentContextItemOriginalId: item.value,
          }
        : contextItem,
    );
    setContextItems(updatedCtxItems);
    if (item.value && contextItems[index].originalPointer.type !== PointerType.Empty) {
      triggerAgentClient
        .setTriggerContextItemId(
          snapshot.id,
          step.id,
          contextItems[index].secondAgentContextItemOriginalId,
          contextItems[index].secondAgentContextItemOriginalId,
        )
        .then(() =>
          updateFlow(() => {
            step.contextItems = updatedCtxItems;
          }),
        );
    }
  };

  if (!step) {
    return <React.Fragment />;
  }

  return (
    <StepView step={step} styling={TriggerAgentStyling} isCollapsible={true} manipulateStep={manipulateStep}>
      <Field inputId="agent" label="Agent" name="Agent" groupClass="col">
        <Select
          options={agentOptions}
          value={{ label: getBotById(step.botId)?.name || '', value: step.botId }}
          onChange={(val) => {
            val && onAgentSelect(val);
          }}
          className="trigger-agent-agent-select mb-2"
        />
      </Field>
      <Field inputId="channel" label="Channel" name="Channel" groupClass="col">
        <Select
          options={[
            {
              value: ChannelType.SMS,
              label: ChannelType.SMS,
            },
            {
              value: ChannelType.Outbound,
              label: ChannelType.Outbound,
            },
          ]}
          value={{
            value: step.channelType,
            label: step.channelType,
          }}
          isLoading={isLoadingEntryPoints}
          onChange={(val) => val && onChannelSelect(val)}
          className="trigger-agent-entry-point-channel-select mb-2"
        />
      </Field>
      <Field inputId="entryPoint" label="Entry Point" name="Entry Point" groupClass="col">
        <Select
          options={selectedAgentEntryPointsListOptions}
          value={{
            label: selectedAgentEntryPoints.find((ep) => ep.originalId === step.entryPointOriginalId)?.name || '',
            value: step.entryPointOriginalId,
          }}
          isDisabled={!step.botId}
          isLoading={isLoadingEntryPoints}
          onChange={(val) => val && onEntryPointSelect(val)}
          className="trigger-agent-entry-point-select mb-2"
        />
      </Field>

      <Field inputId="destinationId" label="Destination" name="Destination" groupClass="col">
        <PointerControl
          types={[PointerType.Context, PointerType.Iteration]}
          pointer={new ContextPointer(step.destinationId, EmptyId)}
          update={(val) => onDestinationSelect(val)}
          pointerFilters={[PointerControlFilters.HideStaticContextItems, PointerControlFilters.HideCreateOption]}
        />
      </Field>
      <Fragment>
        <h5 className="mt-3 mb-0">Context items:</h5>
        {contextItems.map((item, index) => (
          <div className="mb-2 shadow p-2 bg-white rounded">
            <div className="context-heading">
              <h6>Second agent context item:</h6>
              <Button
                isDisabled={bots.isReadOnlyBot}
                type={VisualCategory.Danger}
                className="btn-sm"
                action={() => deleteContextItem(index)}
              >
                <Icon type={IconType.Delete} />
              </Button>
            </div>
            <Select
              options={secondAgentContexts.map((ctx) => ({
                label: ctx.name,
                value: ctx.id,
              }))}
              hideSelectedOptions={true}
              value={{
                label: getContextItemName(item.secondAgentContextItemOriginalId),
                value: item.secondAgentContextItemOriginalId,
              }}
              onChange={(val) => val && onSecondAgentContextOptionChange(val, index)}
              key={`secondAgentContextItem-${index}`}
            />
            {item.secondAgentContextItemOriginalId && (
              <div>
                <h6>Current agent source:</h6>
                <PointerControl
                  key={`source-${index}`}
                  types={[PointerType.Config, PointerType.Context, PointerType.DirectAssignment, PointerType.Iteration]}
                  pointer={item.originalPointer}
                  update={(p) => changeContextItems(p, index)}
                  pointerFilters={[PointerControlFilters.HideCreateOption]}
                />
              </div>
            )}
          </div>
        ))}
      </Fragment>

      <Button
        className={'mt-2'}
        isDisabled={bots.isReadOnlyBot || !step.botId}
        type={VisualCategory.Primary}
        action={addContextItem}
      >
        <Icon type={IconType.Add} /> Add
      </Button>
    </StepView>
  );
};
