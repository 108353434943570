import { Bot } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { useClient } from './ClientContext';
import { useTenants } from './TenantContext';
import { PostSuccess } from '../ui/events';

const BotsContext = React.createContext<BotContextType | undefined>(undefined);
const BotContext = React.createContext<Bot | undefined>(undefined);

type BotContextType = {
  bots?: Bot[];
  currentBot?: Bot;
  addNewBot: (bot: Bot) => void;
  setBot: (code?: string) => void;
  refreshBots: () => Promise<void>;
  isReadOnlyBot: boolean;
  updateReadOnly: (value: boolean, showToast?: boolean) => void;
};

export const BotsProvider: React.FC = ({ children }) => {
  const [bots, setBots] = useState<Bot[]>();
  const [currentBot, setCurrentBot] = useState<Bot>();
  const [isReadOnlyBot, setIsReadOnlyBot] = useState(false);
  const client = useClient('bot');
  const tenants = useTenants();

  useEffect(() => {
    if (tenants.selectedTenant) {
      client.getBotsAsync().then((res) => {
        if (res.success) {
          setBots(res.data);
          setCurrentBot(undefined);
        }
      });
    }
  }, [tenants.selectedTenant]);

  const addNewBot = (bot: Bot) => {
    if (!bots) {
      setBots([bot]);
      return;
    }
    setBots([...bots, bot]);
  };

  const refreshBots = async () => {
    var { success, data } = await client.getBotsAsync();
    if (success) {
      setBots(data);
      if (currentBot) {
        setCurrentBot(data!.find((b) => b.id === currentBot?.id));
      }
    }
  };

  const setBot = (code?: string) => {
    const bot = bots?.find((b) => b.code === code);

    setCurrentBot(bot);
  };

  const updateReadOnly = (value: boolean, showToast?: boolean) => {
    setIsReadOnlyBot(value);
    showToast && PostSuccess(`Read-only ${value ? 'on' : 'off'}`);
  };

  return (
    <BotsContext.Provider value={{ bots, addNewBot, refreshBots, currentBot, setBot, isReadOnlyBot, updateReadOnly }}>
      {children}
    </BotsContext.Provider>
  );
};

export const useBots = () => {
  const context = React.useContext(BotsContext);

  if (context === undefined) {
    throw new Error('useBots must be used within a BotsProvider!');
  }

  return context;
};

export const useRefreshBots = () => {
  const context = React.useContext(BotsContext);

  if (context === undefined) {
    throw new Error('useRefreshBots must be used with a BotsProvider!');
  }

  return context.refreshBots;
};

export const useCurrentBot = () => {
  const context = React.useContext(BotsContext);

  if (context === undefined) {
    throw new Error('useBot must be used within a BotsProvider!');
  }

  return context.currentBot;
};
